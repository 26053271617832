<template>
          <div
            :class="['reasons-item__item', `reasons-item__item_${props?.data?.id}`]">
            <div class="reasons-item__item-content">
              {{ data?.reason }}
            </div>
            <div :class="['reasons-item__item-actions', `reasons-item__item-actions_${props?.data?.id}`]" @click="onActionsClick">
              <img
                src="@/assets/images/icons/dots.svg"
                class="reasons-item__item-actions-icon"
                alt="dots"
              />
            </div>
            <div :class="['reasons-item__actions-dropdown',`reasons-item__dropdown_${props?.data?.id}`]" v-show="showActions">
              <div class="reasons-item__action-edit" @click.stop="onEditClick">
                <img
                  src="@/assets/images/icons/pencil.svg"
                  class="reasons-item__action-edit-icon"
                />
                <span class="reasons-item__action-edit-text">Редактировать</span>
              </div>
              <div class="reasons-item__action-delete" @click.stop="onDeleteClick">
                <img
                  src="@/assets/images/icons/delete-red.svg"
                  class="reasons-item__action-delete-icon"
                /><span class="reasons-item__action-delete-text">Удалить</span>
              </div>
            </div>
          </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import settings from "@/api/settings";
import CancelChanges from "@/components/ModalWindow/ModalBodyes/CancelChanges";
import ChangesSaved from "@/components/ModalWindow/ModalBodyes/ChangesSaved";
import AccessClosed from "@/components/AccessClosed";

const props = defineProps(["data"])
const emits = defineEmits(["edit", "delete"])
const router = useRouter();
const showActions = ref(false)

const onActionsClick = () => {
  showActions.value = !showActions.value
}
const onEditClick = () => {
  emits("edit", props?.data)
  showActions.value = false;
}
const onDeleteClick = () => {
  emits("delete", props?.data)
  showActions.value = false;
}

const onOuterDropdownClick = function (e) {
  if (!e.target.closest(`.reasons-item__item_${props.data?.id}`)) {
    showActions.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", onOuterDropdownClick);
})

onUnmounted(() => {
  document.removeEventListener("click", onOuterDropdownClick);
})
</script>

<style scoped lang="scss">
.reasons-item {
  &__item {
    display: flex;
    position: relative;
    width: 100%;
    column-gap: 16px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__item-content {
    padding: 16px;
    background-color: #f4f5f7;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    flex: 1 1 calc(100% - 16px - 24px);
    color: $dark;
  }
  &__item-actions {
    width: 24px;
    height: 24px;
    flex: 1 1 24px;
    cursor: pointer;
  }

  &__item-actions-icon {
    display: block;
    margin-top: 12px;
  }
  &__actions-dropdown {
    position: absolute;
    right: 4px;
    bottom: -72px;
    z-index: +3;
    padding: 7px 12px;
    width: 174px;
    // height: 88px;
    background-color: #fff;
    border: 1px solid $light-gray-1;
    border-radius: 8px;
    // box-shadow: 2px 4px 24px 0 #0000001f;
    box-shadow: 2px 2px 2px 0 #0000001f;
  }
  &__action-edit {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;
    column-gap: 4px;
    cursor: pointer;
  }
  &__action-edit-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    text-align: right;
    color: $blue;
  }
  &__action-delete {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    height: 32px;
    column-gap: 4px;
    cursor: pointer;
  }
  &__action-delete {
    color: $red;
  }
  &__action-delete {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    text-align: right;
  }
}
</style>
