<template>
  <div class="reason-add">
    <div class="reason-add__header">
      <h2 class="reason-add__title">Редактирование причины</h2>
      <div class="reason-add__close" @click.prevent="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15"
            stroke="#566A83"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5L15 15"
            stroke="#566A83"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="reason-add__body">
      <div class="reason-add__form-field">
        <VCustomInput
          class="reason-add__input"
          placeholder="Название причины*"
          :error="!!formErrors.reason"
          @input="validateInput"
          @blur="validateInput"
          v-model="form.reason"
        />
        <p class="reason-add__form-field-error" v-if="formErrors.reason">
          {{ formErrors.reason }}
        </p>
      </div>
    </div>
    <div class="reason-add__btns">
      <VButton
        label="Сохранить"
        bg="#0086B2"
        color="#fff"
        class="reason-add__btn"
        @click="saveReason"
      />

      <VButton
        label="Отменить"
        bg="#E4E7EE"
        bgHover="#BBC4CD"
        color="#1E3959"
        colorHover="#1E3959"
        class="reason-add__btn"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import responses from "@/api/responses";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "@/api/common";
import { debounce } from "@/services/helpers";
const emit = defineEmits(["close", "saveReason"]);
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
const form = ref({
  id: "",
  reason: "",
});

const formErrors = ref({
  reason: "",
});

const validateInput = () => {
  formErrors.value.reason = "";
  if (form.value?.reason?.length < 1) {
    formErrors.value.reason = "Заполните обязательное поле";
  }

  return !formErrors.value?.reason;
};

const saveReason = async () => {
  const valid = validateInput();
  if (!valid) return;
  emit("saveReason", {
    id: props?.data?.currentItem?.id,
    reason: form.value.reason,
  });
};

watch(
  props,
  () => {
    if (props?.data) {
      form.value = props?.data?.currentItem;
    }
  },
  { deep: true }
);
onMounted(() => {
  if (props?.data) {
    form.value = props?.data?.currentItem;
  }
});
</script>
<style lang="scss" scoped>
.reason-add {
  padding: 40px 24px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 284px;
  width: 352px;

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__form-field {
    // display: flex;
  }

  &__form-field-error {
    margin-top: 4px;
    font-size: 14px;
    color: $red;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
  }

  &__routes {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  &__route {
    display: flex;
    padding: 10px 8px;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__route-radio {
    position: relative;
    flex-shrink: 0;
    appearance: none;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray-2;
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border: 3px solid #fff;
      background-color: $blue;
      border-radius: 50%;
      transform: scale(0);
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &__radio-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }

  &__btn {
    &:first-child {
      // width: calc(50% - 8px);
      margin-bottom: 12px;

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      // width: calc(50% - 8px);

      &:active {
        background-color: #006894 !important;
      }
    }
  }
}
</style>
