import api from "./api";

const formDataOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

const responses = {
    getResponses(query,additionalStringQuery) {
        if(additionalStringQuery){
        return api.get(`/jobResponses?${additionalStringQuery}`, { params: query});

        }else {
        return api.get(`/jobResponses`, { params: query});

        }
    },
    getResponse(id) {
        return api.get(`/jobResponses/${id}`);
    },
    postStatus(responseId, statusId, body) {
        return api.post(`/jobResponses/${responseId}/setStatus/${statusId}`, body);
    },
    handleAction(responseId, action, body) {
        return api.post(`/jobResponses/${responseId}/${action}`, body);
    },
    setStatusWithDocuments(responseId, statusId, body) {
        return api.post(`/jobResponses/${responseId}/setStatus/${statusId}`, body,formDataOptions);
    },
    setWithDocuments(responseId, step, body, stage) {
        return api.post(`/jobResponses/${responseId}/${stage}/${step}`, body,formDataOptions);
    },
    getHistory(id) {
        return api.get(`/jobResponses/${id}/history`);
    },
    transferDecision(id,body) {
        return api.post(`/jobResponses/${id}/delayDeadline`,body);
    },
    postMakeResponse(body) {
        return api.post(`/jobResponses`,body);
    },
    getResponseDocuments(id, step, stage) {
        return api.get(`/jobResponses/${id}/${stage}/${step}`);
    },
    getResponsesRejectionReasons() {
        return api.get(`/jobResponsesRejectionReasons`);
    },
    getResponsesVacanciesRoutes(vacancyId) {
        return api.get(`/vacancies/${vacancyId}/routes`);
    },
    postResponsesAcceptRoute(responseId,routeId, stage) {
        return api.post(`/jobResponses/${responseId}/${stage}/acceptResume?vacancy_route_id=${routeId}`);
    },
    postResponsesChangeTickets(routeId, isTickets, stage) {
        return api.post(`/jobResponses/${routeId}/${stage}/setTicketsNeed?tickets_needs=${isTickets ? 1 : 0}`);
    },
};

export default responses;
