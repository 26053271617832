import api from "./api";

const formDataOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

const rejectionReasons = {
    getRejectionReasons() {
        return api.get(`/rejection-reasons`);
    },
    addReason(body) {
        return api.post(`/rejection-reasons`, body);
    },
    updateReason(id,body) {
        return api.patch(`/rejection-reasons/${id}`, body);
    },
    deleteReason(id) {
        return api.delete(`/rejection-reasons/${id}`);
    }
};

export default rejectionReasons;
