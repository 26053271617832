import axios from "axios";
import store from "../store";
import router from "../router";
import eventBus from "@/services/eventBus";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  // }
});

const COMMON_PREFIX = "/api";
const FRONTEND_PREFIX = "/frontend/api";
api.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] = "application/json";
    if (store.getters["auth/token"]) {
      config.headers["Authorization"] = `Bearer ${store.getters["auth/token"]}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push("/authorization");
    } else if (error.response.status !== 400) {
      eventBus.emit("errorRequest");
    }
    return Promise.reject(error);
  }
);

const common = {
  searchResidence(query = "", type = "") {
    return api.get(`${FRONTEND_PREFIX}/address/localities?name=${query}&type=${type}`);
  },
  searchResponsesByPhone(query) {
    return api.get(`${FRONTEND_PREFIX}/jobResponses/byPhone/${query}`);
  },
  getSearchRegions(query) {
    return api.get(`${FRONTEND_PREFIX}/regions`, { params: query });
  },
  getVacancies(search) {
    return api.get(`${FRONTEND_PREFIX}/candidates?vacancy_id=${search}`);
  },
  getCompanyActiveVacancies(search) {
    return api.get(`${FRONTEND_PREFIX}/company-active-vacancies?${search}`);
  },
  getVacanciesReferences(search) {
    return api.get(`${FRONTEND_PREFIX}/candidates/references/vacancies`, {
      body: search,
    });
  },
};

export default common;
