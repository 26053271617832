<template>
  <div class="setting">
    <div class="setting__container">
      <SettingsDate v-if="!$route?.query?.reasons" />
      <SettingsReasons v-else />
    </div>
    <ModalWindow
      v-if="cancelChangesModal"
      @close="cancelChangesModal = false"
      class="setting__cancel-changes-modal"
    >
      <CancelChanges
        @close="cancelChangesModal = false"
        @cancelChanges="cancelChanges"
      />
    </ModalWindow>

    <ModalWindow
      v-if="changesSavedModal"
      @close="changesSavedModal = false"
      class="setting__changes-saved-modal"
    >
      <ChangesSaved @close="changesSavedModal = false" />
    </ModalWindow>
  </div>

  <AccessClosed v-if="settingsList.blocked_by_contract" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import settings from "@/api/settings";
import CancelChanges from "@/components/ModalWindow/ModalBodyes/CancelChanges";
import ChangesSaved from "@/components/ModalWindow/ModalBodyes/ChangesSaved";
import AccessClosed from "@/components/AccessClosed";
import SettingsDate from "@/components/Settings/SettingsDate";
import SettingsReasons from "@/components/Settings/SettingsReasons";
const router = useRouter();

const disabled = ref(false);
const settingsList = ref([]);
const options = computed(() => {
  let dates = Array.from(new Array(30).fill(1), (elem, index) => {
    const label =
      index < 1
        ? "день"
        : index > 1 && index < 4
        ? "дня"
        : index === 20
        ? "день"
        : index >= 4
        ? "дней"
        : "дня";
    return { id: ++index, label: `${index} ${label}` };
  });
  return dates;
});

const lablesMap = {
  1: "HR",
  2: "Кандидат",
  3: "Ответственный",
  5: "Согласовант",
};

const cancelChangesModal = ref(false);
const changesSavedModal = ref(false);

const form = ref([]);

const selectDay = (day, id) => {
  const index = form.value.findIndex((item) => item.status_id === id);
  if (index >= 0) form.value.splice(index, 1);

  form.value.push({
    status_id: id,
    days: day,
  });
};

const getSettings = async () => {
  try {
    settingsList.value = await settings.getSettings();
  } catch (error) {
    console.log(error);
  }
};

const postSettings = async () => {
  if (!form.value.length) {
    changesSavedModal.value = true;
  }
  disabled.value = true;
  try {
    await settings.postSettings({
      settings: form.value,
    });
    disabled.value = false;
    changesSavedModal.value = true;
  } catch (error) {
    disabled.value = false;
    console.log(error);
  }
};

const cancelChanges = () => {
  cancelChangesModal.value = false;
  router.push("/employees");
};

onMounted(getSettings);
</script>

<style scoped lang="scss">
.setting {
  padding-bottom: 96px;

  &__title {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 28px;
    line-height: 34px;
  }

  &__description {
    font-size: 14px;
    line-height: 17px;
    color: #8f9cac;
  }

  &__block {
    width: 100%;
    margin-top: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 #0000000a;
  }

  &__block-header {
    width: 100%;
    padding: 0 24px 16px 24px;
    display: flex;
    border-bottom: 1px solid $light-gray-1;
  }

  &__block-header-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray;
    font-size: 14px;
    font-weight: 600;

    &:first-child {
      width: 45%;
      padding-right: 50px;
    }

    &:last-child {
      width: 55%;
    }
  }

  &__block-options {
    display: flex;
    flex-direction: column;
  }

  &__block-option {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray-1;
    }
  }

  &__block-option-block {
    &_left {
      display: flex;
      align-items: center;
    }
  }

  &__block-option-left-wrapper {
    display: flex;
  }

  &__block-option-num {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }

  &__block-option-name {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }

  &__block-option-select-wrapper {
    width: 100%;
    height: 60px;
    padding: 6px 6px 6px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__block-option-select-label {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
    font-size: 14px;
  }

  &__block-option-select-role {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    color: #8f9cac;
  }

  &__block-option-select {
    width: 118px;

    &::v-deep(.v-select__header) {
      background-color: #fff;
    }

    &::v-deep(.v-select__options) {
      background-color: #fff;
      box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.12);
      max-height: 290px;
      overflow-y: auto;
      scrollbar-width: thin;
    }
  }

  &__btns {
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:last-child {
      width: 42.31%;

      &:active {
        background-color: $gray !important;
      }
    }

    &:first-child {
      width: 53.86%;
    }
  }

  &__cancel-changes-modal {
    &::v-deep(.modal__body) {
      width: 304px;
    }
  }

  &__changes-saved-modal {
    &::v-deep(.modal__body) {
      width: 304px;
    }
  }
}
</style>
