<template>
  <div class="setting-reasons">
    <h1 class="setting-reasons__title">Настройки причин отказов</h1>
    <div class="setting-reasons__wrapper">
      <aside class="setting-reasons__aside">
        <p class="setting-reasons__aside-title">Типы отказов</p>
        <div
          :class="[
            'setting-reasons__aside-item',
            {
              'setting-reasons__aside-item_active':
                chosenRejectionType === 'we',
            },
          ]"
          @click="chosenRejectionType = 'we'"
        >
          Отказали мы
        </div>
        <div
          :class="[
            'setting-reasons__aside-item',
            {
              'setting-reasons__aside-item_active':
                chosenRejectionType === 'candidate',
            },
          ]"
          @click="chosenRejectionType = 'candidate'"
        >
          Отказали нам
        </div>
      </aside>
      <main class="setting-reasons__main">
        <h2 class="setting-reasons__main-title">
          Причины отказа кандидата от прохождения отклика
        </h2>
        <p class="setting-reasons__main-description">
          Вы можете добавлять/удалять причины отказа от дальнейшего прохождения
          отклика, которые кандидат увидит в мобильном приложении
        </p>
        <div class="setting-reasons__main-list setting-reasons__list">
          <SettingsReasonElement
            v-for="(item, index) in currentReasons"
            :key="item.id"
            :data="item"
            @edit="onEditReason"
            @delete="onDeleteReason"
          />
        </div>
        <button class="setting-reasons__main-button" @click="addReason">
          <img
            class="setting-reasons__main-button-icon"
            src="@/assets/images/icons/plus-blue.svg"
            alt="plus"
          /><span class="setting-reasons__main-button-text"
            >Добавить причину</span
          >
        </button>
      </main>
    </div>
    <ModalWindow
      class="setting-reasons__modal-poups"
      :showModalWithOverflow="showModalWindow"
      v-show="showModalWindow"
      @close="showModalWindow = false"
    >
      <component
        v-if="showModalWindow"
        :is="modalComponents"
        :data="{ currentItem, type: chosenRejectionType }"
        @addReason="onAddReason"
        @saveReason="onSaveReason"
        @close="showModalWindow = false"
      />
    </ModalWindow>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import settings from "@/api/settings";
import responses from "@/api/responses";
import rejectionReasons from "@/api/rejectionReasons";
import CancelChanges from "@/components/ModalWindow/ModalBodyes/CancelChanges";
import ChangesSaved from "@/components/ModalWindow/ModalBodyes/ChangesSaved";
import AccessClosed from "@/components/AccessClosed";
import SettingsReasonElement from "@/components/Settings/SettingsReasonElement";
import ReasonAdd from "@/components/ModalWindow/ModalBodyes/SettingsReasons/ReasonAdd";
import ReasonEdit from "@/components/ModalWindow/ModalBodyes/SettingsReasons/ReasonEdit";
import ReasonWarning from "@/components/ModalWindow/ModalBodyes/SettingsReasons/ReasonWarning";
const router = useRouter();
const reasonsItems = ref({
  we: [
    {
      id: 1,
      name: "Нашел другую работу",
    },
    {
      id: 2,
      name: "Не в поиске",
    },
  ],
  candidate: [
    {
      id: 1,
      name: "Нашел другую работу",
    },
    {
      id: 2,
      name: "Не в поиске",
    },
  ],
});
const showModalWindow = ref(false);
const chosenRejectionType = ref("candidate");
const currentItem = ref(null);
const currentModal = ref("");
const modalComponents = computed(() => {
  return {
    ReasonAdd,
    ReasonEdit,
    ReasonWarning,
  }[currentModal.value];
});
const currentReasons = computed(
  () => reasonsItems.value?.[chosenRejectionType.value]
);
const addReason = () => {
  currentModal.value = "ReasonAdd";
  showModalWindow.value = true;
};

const onEditReason = (item) => {
  currentModal.value = "ReasonEdit";
  showModalWindow.value = true;
  currentItem.value = item;
};

const onDeleteReason = (item) => {
  rejectionReasons.deleteReason(item?.id).then((res) => {
    if (res?.status) {
      getRejectionReasons();
    } else {
      currentModal.value = "ReasonWarning";
      showModalWindow.value = true;
      return;
    }
  });
};

const onAddReason = (reason) => {
  showModalWindow.value = false;
  rejectionReasons.addReason(reason).then((res) => {
    if (res) {
      getRejectionReasons();
    }
  });
};

const onSaveReason = ({ id, reason }) => {
  showModalWindow.value = false;

  rejectionReasons.updateReason(id, { reason }).then((res) => {
    if (res) {
      getRejectionReasons();
    }
  });
};

const getRejectionReasons = () => {
  rejectionReasons.getRejectionReasons().then((res) => {
    if (res) {
      reasonsItems.value = res;
    }
  });
};

getRejectionReasons();
</script>

<style scoped lang="scss">
.setting-reasons {
  &__title {
    margin-top: 28px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.89px;
    letter-spacing: 0%;
    color: $dark;
  }
  &__wrapper {
    display: flex;
    column-gap: 32px;
    align-items: flex-start;
  }
  &__aside {
    padding: 12px;
    width: 256px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    flex-grow: 0;
  }
  &__aside-title {
    width: 232px;
    margin-bottom: 12px;
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0%;
    color: $dark;
  }
  &__aside-item {
    width: 232px;
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    color: $dark;
    border-radius: 8px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:hover {
      background-color: #f0f2f4;
    }

    &_active {
      background-color: #f0f2f4;
    }
  }
  &__main {
    width: 832px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
  }
  &__main-title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24.2px;
    letter-spacing: 0%;
    color: $dark;
  }
  &__main-description {
    max-width: 528px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;

    color: $gray;
  }
  &__main-button {
    padding: 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    margin-right: auto;
    outline: none;
    border: none;
    color: $blue;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    text-align: right;
    cursor: pointer;
  }
  &__main-button-icon {
    margin-right: 4px;
  }
  &__list {
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid $light-gray-1;
  }
  &__item {
    display: flex;
    position: relative;
    width: 100%;
    column-gap: 16px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__item-content {
    padding: 15.5px 16px;
    background-color: #f4f5f7;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    flex: 1 1 calc(100% - 16px - 24px);
  }
  &__item-actions {
    width: 24px;
    height: 24px;
    flex: 1 1 24px;
    cursor: pointer;
  }

  &__item-actions-icon {
    display: block;
    margin-top: 12px;
  }
  &__actions-dropdown {
    position: absolute;
    right: 0px;
    bottom: -72px;
    z-index: +5;
    padding: 8px 12px;
    width: 174px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.12);
  }
  &__action-edit {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;
    column-gap: 4px;
    cursor: pointer;
  }
  &__action-delete {
    display: flex;
    align-items: center;
    height: 32px;
    column-gap: 4px;
    cursor: pointer;
  }
  &__modal-poups {
    &::v-deep(.modal__body) {
      width: auto;
      height: auto;
      border-radius: 16px;
    }
  }
}
</style>
